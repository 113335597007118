<template>
  <v-container fluid class="all-notifications-container" v-if="notifications.length">
    <v-row class="ma-0">
      <v-col cols="12" class="pa-0">
        <v-list class="w-100 notifications-container" v-if="todaysNotificaions().length">
          <v-row class="ma-0 pt-4">
            <v-col class="d-flex align-center pt-0 pb-0 pl-0">
              <div class="text-uppercase font-label">Today</div>
            </v-col>
            <v-col class="d-flex justify-end pa-0">
              <v-btn text height="26" class="pa-0" v-if="false">
                <span class="me-2 view-text text-purple">Mark All as Read</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mb-4"></v-divider>
          <NotificationComponent
            v-for="notification in todaysNotificaions()"
            :notification="notification"
            :key="notification.id"
            class="notification"
          />
        </v-list>
        <v-list class="w-100 notifications-container" v-if="yesterdaysNotifications().length">
          <v-row class="ma-0 pt-4">
            <v-col class="d-flex align-center pt-0 pb-0 pl-0">
              <div class="text-uppercase font-label">Yesterday</div>
            </v-col>
            <v-col class="d-flex justify-end pa-0">
              <v-btn text height="26" class="pa-0" v-if="false">
                <span class="me-2 view-text text-purple">Mark All as Read</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mb-4"></v-divider>
          <NotificationComponent
            v-for="notification in yesterdaysNotifications()"
            :notification="notification"
            :key="notification.id"
            :displayTime="true"
            class="notification"
          />
        </v-list>
        <v-list class="w-100 notifications-container" v-if="earlierNotifications().length">
          <v-row class="ma-0 pt-4">
            <v-col class="d-flex align-center pt-0 pb-0 pl-0">
              <div class="text-uppercase font-label">Earlier</div>
            </v-col>
            <v-col class="d-flex justify-end pa-0">
              <v-btn text height="26" class="pa-0" v-if="false">
                <span class="me-2 view-text text-purple">Mark All as Read</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mb-4"></v-divider>
          <NotificationComponent
            v-for="notification in earlierNotifications()"
            :notification="notification"
            :key="notification.id"
            class="notification"
          />
        </v-list>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else class="no-notification-container pt-0 pb-3">
    <v-row class="ma-0 d-flex justify-center">
      <img src="@/assets/svg/theme/group.svg"/>
    </v-row>
    <v-row class="ma-0 mt-6 notification-text">
      <v-col class="d-flex justify-center pb-0 main-text" cols="12">
        <div>No notifications yet.</div>
      </v-col>
        <v-col class="d-flex justify-center pt-0 pl-0 sub-text" cols="12">
        <div>When you have new notifications, you'll see them here.</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment-shortformat';
import NotificationComponent from './NotificationComponent';

export default {
  name: 'NotificationsScreen',
  components: {
    NotificationComponent,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('dashboard', ['notifications']),
  },
  methods: {
    ...mapActions('dashboard', ['getNotifications']),
    todaysNotificaions() {
      return _.filter(
        this.notifications,
        (val) => !moment(val.createdAt).isBefore(moment(), 'day'),
      );
    },
    yesterdaysNotifications() {
      const yesterday = moment().subtract(1, 'days');
      return _.filter(this.notifications, (val) => moment(val.createdAt).isSame(yesterday, 'd'));
    },
    earlierNotifications() {
      const yesterday = moment().subtract(1, 'days');
      return _.filter(this.notifications, (val) => moment(val.createdAt).isBefore(yesterday, 'd'));
    },
  },
  mounted() {
    this.getNotifications('ALL');
  },
};
</script>

<style lang="scss" scoped>
.notifications-container {
  background-color: $neutral6;
}
.view-text {
  font-size: 14px;
  font-family: $fontFamily1;
}

.time-ago {
  font-family: $fontFamily1;
  font-weight: bold;
  color: $neutral1;
}
.all-notifications-container {
  max-width: 700px;
}
.no-notification-container {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media (max-width: 600px) {
  .all-notifications-container,.no-notification-container {
    padding-top: 40px;
  }
}
</style>
